import(/* webpackMode: "eager" */ "/app/assets/kitchen-with-stools.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/client.analytics-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/client.data-dog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/client.log-rocket.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/client.track-user.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.css");
