"use client";

import { useEffect } from "react";
import { storeUserTrackingParamsInSession } from "../utilities/trackUser";

export default function TrackUsers() {
	useEffect(() => {
		storeUserTrackingParamsInSession();
	}, []);
	return null;
}
