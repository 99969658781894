// Necessary if using App Router to ensure this file runs on the client
"use client";

import LogRocket from "logrocket";
import {
	IS_AUTOMATED_TESTING,
	IS_PRODUCTION_BUILD,
	IS_SSR,
	LOG_ROCKET_KEY,
} from "../utilities/env";

const isBot = !IS_SSR && window.navigator.userAgent.includes("bot");

if (IS_PRODUCTION_BUILD && !IS_AUTOMATED_TESTING && !isBot && LOG_ROCKET_KEY) {
	LogRocket.init(LOG_ROCKET_KEY);
}

export default function LogRocketInit() {
	// Render nothing - this component is only included so that the init code
	// above will run client-side
	return null;
}

export { LogRocketInit };
