// they don't want the actual keys that are set from the actual trackers
// so we need a way to transform them into the keys they want.
const userTrackingKeyMap = {
	utm_source: "UtmSource",
	utm_medium: "UtmMedium",
	utm_campaign: "UtmCampaign",
	utm_term: "UtmTerm",
	utm_content: "UtmContent",
	gclid: "GoogleClickId",
	msclkid: "BingClickId",
	fbclid: "FacebookClickId",
	gaclientid: "GAClientId",
} as const;

type TUserTrackingKeyMap = typeof userTrackingKeyMap;

const userTrackingSessionKey = "user_tracking_data";

export function storeUserTrackingParamsInSession() {
	if (typeof window === "undefined") return;
	const searchParams = new URLSearchParams(window.location.search);
	let trackingData: Record<string, string> = getUserTrackingParamsInSession();
	for (const [k, v] of searchParams.entries()) {
		if (k in userTrackingKeyMap)
			trackingData[userTrackingKeyMap[k as keyof TUserTrackingKeyMap]] = v;
	}
	// need to cast window to `any` here since ga mutates the global window object.
	trackingData[userTrackingKeyMap.gaclientid] =
		(window as any)?.gaGlobal?.vid || "";
	window.sessionStorage.setItem(
		userTrackingSessionKey,
		JSON.stringify(trackingData),
	);
}

type TUserTrackingMap = {
	[Property in keyof TUserTrackingKeyMap as TUserTrackingKeyMap[Property]]?: string;
};

export function getUserTrackingParamsInSession(): TUserTrackingMap {
	const userTrackingDataJSON = window.sessionStorage.getItem(
		userTrackingSessionKey,
	);
	if (userTrackingDataJSON) return JSON.parse(userTrackingDataJSON);
	return {};
}
