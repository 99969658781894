"use client";

import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

import { analytics } from "../utilities/segment";

const AnalyticsPageView = function () {
	const [currPath, setCurrPath] = useState<string | null>("");
	const pathname = usePathname();

	useEffect(() => {
		if (currPath != pathname) {
			analytics?.page();
		}
		setCurrPath(pathname);
	}, [pathname, currPath]);
	return null;
};

export default AnalyticsPageView;
